import React, { Component } from 'react';
import { connect } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import ReactDataGrid from 'react-data-grid';
import { Editors } from "react-data-grid-addons";
import * as Estimates from './Saga/Estimates';
import Button from '@mui/material/Button';
import { ModalBody,ModalFooter,Modal,Tooltip} from 'reactstrap';
// import * as Tenants from './Saga/Tenants';

// import '../../Css/Table.css';
// import '../../Css/Grid.css';
// import './Css/Tenant.css';
// import { EditorBase } from 'common/editors';
let autokana1;
const { DropDownEditor} = Editors;
// const { DropDownFormatter } = Formatters;
// const month1Types = [
//     { id: 0 ,value: "" },
//     { id: 2,value:"込み"},
    
// ];
// const month2Types = [
//     { id: 0 ,value: "定額" },
//     { id: 1 ,value: "実費" },
//     { id: 2,value:"込み"},
    
// ];
const payTypes = [
    { id: 0 ,value: "" },
    { id: 2,value:"込み"},
    { id: 3,value: "無し"}
];
const payUpdateTypes = [
    { id: 0 ,value: "" },
    { id: 3,value:"無し"},
];
const payTaikyoTypes = [
    { id: 1 ,value: "実費" },
    { id: 2,value:"込み"},
];

const monthTypes = [
    { id: 0 , value: ""  }
    ,{ id: 1, value: "1ヵ月"  }
    ,{ id: 2,value: "2ヵ月" }
    ,{ id: 3,value: "3ヵ月" }
    ,{ id: 4,value: "4ヵ月" }
    ,{ id: 5,value: "5ヵ月" }
    ,{ id: 6,value: "6ヵ月" }
    ,{ id: 7,value: "7ヵ月" }
    ,{ id: 8,value: "8ヵ月" }
    ,{ id: 9,value: "9ヵ月" }
    ,{ id: 10,value: "10ヵ月" }
    ,{ id: 11,value: "11ヵ月" }
    ,{ id: 12,value: "12ヵ月" }
];
const yearTypes = [
    { id: 0 , value: ""  }
    ,{ id: 1, value: "1年間"  }
    ,{ id: 2,value: "2年間" }

];
const year=[];
year[""]=0;
year["1年間"]=1;
year["2年間"]=2;


const month=[];
month[""]=0;
month["1ヵ月"]=1;
month["2ヵ月"]=2;
month["3ヵ月"]=3;
month["4ヵ月"]=4;
month["5ヵ月"]=5;
month["6ヵ月"]=6;
month["7ヵ月"]=7;
month["8ヵ月"]=8;
month["9ヵ月"]=9;
month["10ヵ月"]=10;
month["11ヵ月"]=11;
month["12ヵ月"]=12;


const monthno=[];
monthno[0]="";
monthno[1]="1ヵ月";
monthno[2]="2ヵ月";
monthno[3]="3ヵ月";
monthno[4]="4ヵ月";
monthno[5]="5ヵ月";
monthno[6]="6ヵ月";
monthno[7]="7ヵ月";
monthno[8]="8ヵ月";
monthno[9]="9ヵ月";
monthno[10]="10ヵ月";
monthno[11]="11ヵ月";
monthno[12]="12ヵ月";




class TooltipItem extends Component {
  // const TooltipItem = props => {
      constructor(props) {
          super(props);
      // const { item, id } = props;
      this.state = {
          tooltipOpen:false
      }
      this.toggle = this.toggle.bind(this);
      // [tooltipOpen, setTooltipOpen] = useState(false);
      }
      // const [tooltipOpen, setTooltipOpen] = useState(false);
  //  toggle = () => this.setTooltipOpen(!this.tooltipOpen);
   toggle() {
      this.setState({tooltipOpen:!this.state.tooltipOpen})
   }
      componentDidMount() {
        // this.setState({tooltipOpen:true})
          // this.setState({tooltipOpen:this.props.item.open===true?true:false})
      }
      render() {
      return (
        <span>
      <span   id={"Tooltip-" + this.props.id} >{this.props.value.amount===0?"　":this.props.value.amount.toLocaleString()}</span>
      {this.props.value.checkAmount>0?<Tooltip
          data-placement="right"
            placement={this.props.item.placement}
            isOpen={this.state.tooltipOpen}
            target={"Tooltip-" + this.props.id}
            toggle={this.toggle}
            className="red-tooltip"
          >{this.props.value.checkRemarks}</Tooltip>:""}
        </span>
      );
      }
    };





    class DropDownEditor2 extends  React.Component  {
      constructor(props) {
          super(props);
          this.state = { rentMEtcId: props.value };
        
        }
      getInputNode() {
          
        return ReactDOM.findDOMNode(this);
        
      }
      getValue() {
          return { rentMEtcId:this.state.rentMEtcId};
        }
      onClick() {
        this.getInputNode().focus();
      }
    
      onDoubleClick() {
        this.getInputNode().focus();
      }
    onChange(value){
      this.setState({ rentMEtcId: value.currentTarget.value });
  
    }
      render() {
        return (
          <select  defaultValue={this.props.value} onBlur={this.props.onBlur} onChange={this.onChange.bind(this)} >
            {this.renderOptions()}
          </select>);
      }
    
      renderOptions() {
        let options = [];
        console.log(this.props)
      
        let types = this.props.options.etcTypes
         if(this.props.rowData.rentMAccountId===11){
          types =this.props.options.hokenTypes
        }else if(this.props.rowData.rentMAccountId===9){
          types =this.props.options.payUpdateTypes
        }else if(this.props.rowData.rentMAccountId===10){
          types =this.props.options.payUpdateTypes
        }else if(this.props.rowData.rentMAccountId===12){
          types =this.props.options.payUpdateTypes
        }else if(this.props.rowData.rentMAccountId===14){
          types =this.props.options.parkingTypes
        }
        types.forEach(function(payload) {
          // if (typeof(name) === 'string') {
            options.push(<option key={payload.id} value={payload.id}>{payload.value}</option>);
          // } else {
          //   options.push(<option key={name.id} value={name.value} title={name.title}  >{name.text || name.value}</option>);
          // }
        }, this);
   
        return options;
      }
    }
class MoneyEditComponent extends Component {
    
    constructor(props) {
        super(props);



        let columns=[]
        let columnsCorp=[]
    
        const cloumnkoumoku =  { key: 'name', name: '項目名', width: 100,  
        editor: <DropDownEditor options={this.props.onetimeKoumoku} /> ,
        editable:  function (rowData) {
         return (rowData.rentMAccountId!==9 && rowData.rentMAccountId!==10 && rowData.rentMAccountId!==11 && rowData.rentMAccountId!==12 && rowData.rentMAccountId!==14 && rowData.rentMAccountId!==-1) ? true : false; 
        }
          ,formatter:function (props) {
       
          return  (props.row.rentMAccountId===9 || props.row.rentMAccountId===10 || props.row.rentMAccountId===11 || props.row.rentMAccountId===12 || props.row.rentMAccountId===14 || props.row.rentMAccountId===-1)?<div style={{background: '#CCC',textAlign:'Left',paddingLeft:"5px"}}>{props.value}</div>:<div style={{textAlign:'Left',paddingLeft:"5px"}}>{props.value}　</div>
        }}
        columns.push(cloumnkoumoku)
        columnsCorp.push(cloumnkoumoku)
  
        const columntypeDisp= { key: 'rentMEtcId', name: '請求', width: 80 ,
     
        editable:  function (rowData) {
            return (rowData.id===0) ? false : ((rowData.rentMAccountId===33 || rowData.rentMAccountId===23 || rowData.rentMAccountId===-1)?false:true);
        },
      
        formatter:function (props) {
  
  //  console.log(props)
  //  alert(props.row.rentMEtcId)
  //     alert(this.props.payTypes.filter(a=>a.id===props.row.rentMEtcId)[0].value)
        return  props.row.id===0?<div style={{background: '#CCC'}}>　</div>:((props.row.rentMAccountId===33 || props.row.rentMAccountId===23 || props.row.rentMAccountId===-1) ?<div style={{background: '#CCC'}}>　</div>:<div style={{background: 'pink'}}>　{this.props.payTypes.filter(a=>a.id===props.row.rentMEtcId)[0].value}</div>);
        }.bind(this),
        editor: <DropDownEditor2 options={this.props}/>}
      columns.push(columntypeDisp)
      columnsCorp.push(columntypeDisp)
      const columnamountMonth={ key: 'amountMonth', name: '月数', width: 60 , editor: <DropDownEditor options={monthTypes} /> ,
      formatter: function (props) {
      return (parseInt(props.row.rentMEtcId)<=0 && (props.row.rentMAccountId===9 || props.row.rentMAccountId===10)) ? (props.value===0?"":this.props.monthTypes.filter(a=>a.id===props.value)[0].value) :<div style={{background: '#CCC'}}>{(props.value===0?"":props.value)}　</div>;
      }.bind(this),
      editable:  function (rowData) {
        return  (parseInt(rowData.rentMEtcId)<=0 && (rowData.rentMAccountId===9 || rowData.rentMAccountId===10)) ?  true: false;
        
        }

    }
    columns.push(columnamountMonth)
    columnsCorp.push(columnamountMonth)


      const columnamount = { key: 'amount', name: '金額', width: 100 ,
  formatter: function (props) {
  // console.log(props.row)
  return <div style={{background: (props.row.checkAmount===2?'#00ff7f':props.row.checkAmount===1?'#ff69b4':((props.row.rentMEtcId>0 || props.row.rentMAccountId===-1)?'#CCC':"")),textAlign: 'right',paddingRight:'2px'}} ><TooltipItem key={props.row.id+"-4"} item={{placement:"right",text:props.row.name,toggle:true,open:true}} id={props.row.id+"-4"}　value={props.row}/></div>
  // return (!props.row.rentMEtcId>0) ? (props.value===0?"":<div style={{textAlign: 'right',padding:'2px'}}>{props.value.toLocaleString()}</div>) :<div style={{background: '#CCC'}}>{props.value===0?"":props.value}　</div>;
  },
  
  editable:  function (rowData) {

  return  (rowData.rentMEtcId>0 || rowData.rentMAccountId===-1 ) ?  false: true;
  
  }
  
  }
  columns.push(columnamount)
  columnsCorp.push(columnamount)
  
      const columntaxAmount = { key: 'taxAmount', name: '税', width: 50 , 
  formatter: function (props) {
  // console.log(props.row)
  return (props.row.rentMEtcId>0 || props.row.rentMAccountId===-1) ?<div style={{background: '#CCC'}}>{props.row.rentMAccountId===-1 && props.row.taxAmount>0?props.row.taxAmount.toLocaleString():"　"}</div>:""
  },
  editable:  function (rowData) {
    return  (rowData.rentMEtcId>0 || rowData.rentMAccountId===-1) ?  false: true;
    
    }
  }
  columns.push(columntaxAmount)
  columnsCorp.push(columntaxAmount)
  
  
  
      const columntype= { key: 'billing', name: '入居者', width: 60 , 
      formatter: function (props) {
      // console.log(props.row)
      return (props.row.rentMEtcId>0 || props.row.rentMAccountId===-1) ?<div style={{background: '#CCC'}}>　</div>:"" 
      },
      editable:  function (rowData) {
        return  (rowData.rentMEtcId>0 || rowData.rentMAccountId===-1) ?  false: true;
        
        }
      }

      columnsCorp.push(columntype)
      // const columnworks= { key: 'works', name: '取扱', width: 60 ,
      //       formatter: function (props) {
      //           return (props.row.type===0) ?(props.value===1?Math.round(props.row.seikyuuGaku*1.1):""):<div style={{background: '#CCC'}}>{(props.value===0?"":props.value)}　</div>;
      //       },
           
      //   }
      //   columns.push(columnworks)
  
  const columnremarks= { key: 'remarks', name: '備考', width: 100 ,editable:function (rowData) {
    return  (rowData.rentMAccountId!==-1)  ?  true: false;
    
    },
    formatter: function (props) {
      // console.log(props.row)
      return (props.row.rentMAccountId === -1) ? <div style={{ background: '#CCC' }}>　</div> : props.row.remarks 
      },}
  
  columns.push(columnremarks)
  columnsCorp.push(columnremarks)
  const columndelete= { key: 'delete', name: '削除', width: 40,
  formatter: function (props) {
    // console.log(props.row)
    return (props.row.rentMAccountId!==9 && props.row.rentMAccountId!==10 && props.row.rentMAccountId!==11 && props.row.rentMAccountId!==12 && props.row.rentMAccountId!==14  && props.row.rentMAccountId!==-1) ?"":<div style={{background: '#CCC'}}>　</div>
    },
    editable:  function (rowData) {
      return  (rowData.rentMAccountId!==9 && rowData.rentMAccountId!==10 && rowData.rentMAccountId!==11 && rowData.rentMAccountId!==12 && rowData.rentMAccountId!==14  && rowData.rentMAccountId!==-1)  ?  true: false;
      
      }
 }
  columns.push(columndelete)
  columnsCorp.push(columndelete)











    
        this.state = {
            customerinfo: this.props.forecasts,
            startDate: new Date(),
            refresh: true,
            columns : columns,
            columnsCorp: columnsCorp,
            alertopen: false,

        };
        this.handleChange = this.handleChange.bind(this);
        // this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        // this.onRowsTukiUpdated = this.onRowsTukiUpdated.bind(this);
        this.onRowsKeiyakuUpdated = this.onRowsKeiyakuUpdated.bind(this);
        // this.onRowsKoushinUpdated = this.onRowsKoushinUpdated.bind(this);
        // this.onRowsKoushinUpdated = this.onRowsKoushinUpdated.bind(this);
        
        // this.getMonthRowCount = this.getMonthRowCount.bind(this);
        // this.getLeaveRowCount = this.getLeaveRowCount.bind(this);
        this.refresh = this.refresh.bind(this);
        this.add = this.add.bind(this);
        this.getRowCount = this.getRowCount.bind(this);
        this.getCellActions = this.getCellActions.bind(this);

        this.handleClose = this.handleClose.bind(this);
        
        
    }
    
    handleChange = (event) => {
        alert(event.target.name)
          // console.log(event)

          let estimateData= this.props.estimateData
   
            estimateData[event.target.name] = event.target.value
        // }
          this.props.change_estimateData(estimateData)
          this.setState({change:true})
          this.props.check_estimates()
      }
      add = (value) => {
        
        this.props.add_estimateMonies2(value)
        
        this.refresh()
        this.props.check_estimates()
        
      }

    onChange(e) {
        // console.log(e.target.value);
        this.setState({ name1: e.target.value });
        this.props.check_estimates()
    }

    componentDidMount() {
    
        // This method is called when the component is first added to the document
        // this.ensureDataFetched();
// if(this.state.selected){
        window.addEventListener('scroll', event => this.watchCurrentPosition(), true)
    // }
        //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
    }
    watchCurrentPosition(){
        // console.log(document.activeElement.className)

        if(document.activeElement.className!=="" && document.activeElement.className.indexOf("editor-main")){
        // document.activeElement.blur()
    }
    }

    componentDidUpdate(prevProps) {

      if (this.props.estimate_onetime_flg===true) {
   
        this.props.change_estimate_onetime_flg(false)
     this.refresh() 
      }
    }




    

    onRowsKeiyakuUpdated = ({fromRow, toRow, updated }) => {
      // console.log(updated)

      let estimateMonies2 = this.props.estimateMonies2;
      for (let i = fromRow; i <= toRow; i++) {


        if ('name' in updated) {
          
        const chinryouKoumokuNo =   this.props.tm_chinryou_koumokus.filter(a=>a.chinryouKoumokuName===updated.name)[0].chinryouKoumokuNo

        if(estimateMonies2.filter(a=>a.rentMAccountId===chinryouKoumokuNo).length>0){

this.setState({alertopen:true})
          }else{
            estimateMonies2[i].rentMAccountId= chinryouKoumokuNo
            estimateMonies2[i].name= updated.name
          }
       
      }

      //   if ('amount' in updated) {
      //     estimateMonies2[i].amount=parseInt(updated.amount.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
      //       return String.fromCharCode(s.charCodeAt(0) - 65248);
      //   }))



      // }


      if ('amount' in updated) {
        estimateMonies2[i].amount=parseInt(updated.amount.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
      }))

      if((estimateMonies2[i].rentMAccountId===9 || estimateMonies2[i].rentMAccountId===10) && estimateMonies2[i].amount%this.props.estimateMonies1.filter(a=>a.rentMAccountId===1)[0].amount=== 0){
        estimateMonies2[i].amountMonth= estimateMonies2[i].amount/this.props.estimateMonies1.filter(a=>a.rentMAccountId===1)[0].amount
      }else{
        estimateMonies2[i].amountMonth= 0
      } 
     

    }
          
        //   if(updated.amountMonth && isNaN(updated.amountMonth)===true){
        //     estimateMonies2[i].amountMonth= this.props.monthTypes.filter(a=>a.value===updated.amountMonth)[0].id
        // }else if(isNaN(updated.amountMonth)===false){
        //   estimateMonies2[i].amountMonth= 0
        // }

        if(updated.amountMonth && isNaN(updated.amountMonth)===true){
   
          estimateMonies2[i].amountMonth= this.props.monthTypes.filter(a=>a.value===updated.amountMonth)[0].id
          estimateMonies2[i].amount= this.props.estimateMonies1.filter(a=>a.rentMAccountId===1)[0].amount*estimateMonies2[i].amountMonth
          estimateMonies2[i].taxAmount= estimateMonies2.tax===1?Math.round(estimateMonies2[i].amount*0.1):0
        }else if(isNaN(updated.amountMonth)===false){
          estimateMonies2[i].amountMonth= 0
          estimateMonies2[i].amount= 0
          estimateMonies2[i].taxAmount= 0
        }




        
    if ('remarks' in updated) {
      estimateMonies2[i].remarks=updated.remarks
  }


          if(parseInt(updated.rentMEtcId)===2){
            estimateMonies2[i].rentMEtcId=2
            estimateMonies2[i].amount=0
            estimateMonies2[i].amountMonth=0
            estimateMonies2[i].tax=0
            estimateMonies2[i].taxAmount=0
          }else if(parseInt(updated.rentMEtcId)===3){
            estimateMonies2[i].rentMEtcId=3
            estimateMonies2[i].amount=0
            estimateMonies2[i].amountMonth=0
            estimateMonies2[i].taxAmount=0
            estimateMonies2[i].tax=0
          }else if(parseInt(updated.rentMEtcId)===0 || parseInt(updated.rentMEtcId)===-1){
            estimateMonies2[i].rentMEtcId=-1
            estimateMonies2[i].amount=0
            estimateMonies2[i].amountMonth=0
            estimateMonies2[i].taxAmount=0
            estimateMonies2[i].tax=0
              // rows[i].seikyuuGaku=0
              // rows[i].seikyuuGakuTsukiSuu=0
              // rows[i].isKazei=0
              // rows[i].seikyuuNenSuu=0
          }
          // if(updated.seikyuuNenSuuDisp){
          //   estimateMonies2[i].eachYear=year[updated.seikyuuNenSuuDisp]
          // }

      //     if(updated.typeDisp){
             
      //       estimateMonies2[i].eachYear=0
      //       estimateMonies2[i].seikyuuNenSuuDisp=""
       
      // }
          // rows[i] = { ...this.props.money_update[i], ...updated };
      }
      this.props.change_estimateMonies2(estimateMonies2);
      this.props.check_estimates()
      // this.forceUpdate();
      this.refresh();
        // this.forceUpdate();
      //   return this.props.rowss ;k

    };

 

   getCellActions(column, row) {
    
    if(column.key==="taxAmount" && (row.rentMEtcId===0 || row.rentMEtcId===-1) && row.rentMAccountId!==-1){
        return [{
        icon: <div className="float-right" style={{textAlign:"right",paddingRight:"2px",width:"50px",color:"#000000"}}>{this.props.estimateMonies2.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount===0?"":this.props.estimateMonies2.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount.toLocaleString()}</div>,
        callback: () => {
         
          let estimateMonies2 = this.props.estimateMonies2
       
          if(estimateMonies2.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount>0){
            estimateMonies2.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount= 0
            estimateMonies2.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].tax= false
        }else{
            estimateMonies2.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].taxAmount=  Math.round(row.amount*0.1)
            estimateMonies2.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].tax= true
        }

          this.props.change_estimateMonies2(estimateMonies2)
          this.props.check_estimates()
          this.setState({refresh:!this.state.refresh})
        }
      }]
  
   


    }else if(column.key==="billing" && !row.type>0 && row.rentMAccountId!==-1){
      

      return [{
     icon: <div style={{width:"60px"}}>{row.billing===1?<i class="fas fa-check"></i>:""}</div>,
          
          callback: () => {
            let estimateMonies2 = this.props.estimateMonies2

            estimateMonies2.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].billing= estimateMonies2.filter(a=>a.rentMAccountId===row.rentMAccountId)[0].billing=== 0?1:0
            this.props.change_estimateMonies2(estimateMonies2)
            this.props.check_estimates()
            this.setState({refresh:!this.state.refresh})
            
          }
        }
      ]
  
}else if(column.key==="delete" && row.rentMAccountId!==-1 && row.rentMAccountId!==9 && row.rentMAccountId!==10 && row.rentMAccountId!==11 && row.rentMAccountId!==12 && row.rentMAccountId!==14){
    return [{
        icon:<i class="fas fa-trash-alt" style={{marginRight:"15px"}}></i>,
        
        callback: () => {
          if(row.rentMAccountId===0 || row.rentMAccountId>4){
          let estimateMonies2 = this.props.estimateMonies2

          estimateMonies2=  estimateMonies2.filter(a=>a.rentMAccountId!==row.rentMAccountId)
          this.props.change_estimateMonies2(estimateMonies2)
          this.props.check_estimates()
          this.setState({refresh:!this.state.refresh})
          
        }
        }
      }
    ]
}


    

      }

    //   getMonthRowCount() {
    //     let count = this.props.money_onetime.length;
    //     if(this.state.refresh && count > 0) {
    //       count--; // hack for update data-grid
    //       this.setState({
    //         refresh: false
    //       });
    //     }
    
    //     return count;
    //   }

    //   getLeaveRowCount() {
    //     let count = this.props.money_leave.length;
    //     if(this.state.refresh && count > 0) {
    //       count--; // hack for update data-grid
    //       this.setState({
    //         refresh: false
    //       });
    //     }
    
    //     return count;
    //   }
  handleClose = () => {
      this.setState({alertopen:false});
    };


      refresh() {
        this.setState({
          refresh: true
        });
      }

selected(){
    alert()
}
getRowCount() {
    let count = this.props.estimateMonies2.length;
    if(this.state.refresh && count > 0) {
      count--; // hack for update data-grid
      this.setState({
        refresh: false
      });
    }

    return count;
  }
    render() {
       
        // const { handleSubmit } = this.props;
      
       
        return (
            <div className="item"  id="parentDivOfGrid">
          
                    <div style={{ margin: 2 }}>
                        <span className="float-left" >契約時</span>
                        <Button
                    color="primary"
                    variant="outlined"
                    size="small" 
                    type="submit"
                    style={{margin:2,minWidth:0}}
                    onClick={()=>this.add(12)}
                >仲介手数料
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small" 
                    type="submit"
                    style={{margin:2,minWidth:0}}
                    onClick={()=>this.add(2)}
                >保単1年
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small" 
                    type="submit"
                    style={{margin:2,minWidth:0}}
                    onClick={()=>this.add(3)}
                >保単2年
                </Button>

                <Button
                    color="primary"
                    variant="outlined"
                    size="small" 
                    type="submit"
                    style={{margin:2,minWidth:0}}
                    onClick={()=>this.add(4)}
                >保ファ1年
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small" 
                    type="submit"
                    style={{margin:2,minWidth:0}}
                    onClick={()=>this.add(5)}
                >保ファ2年
                </Button>
                {/* <Button
                    color="primary"
                    variant="outlined"
                    size="small" 
                    type="submit"
                    style={{margin:2,minWidth:0}}
                    onClick={()=>this.add(1)}
                >安心
                </Button> */}
                <Button
                    color="primary"
                    variant="outlined"
                    size="small" 
                    type="submit"
                    style={{margin:2,minWidth:0}}
                    onClick={()=>this.add(7)}
                >消毒
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small" 
                    type="submit"
                    style={{margin:2,minWidth:0}}
                    onClick={()=>this.add(6)}
                >抗菌
                </Button>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small" 
                    type="button"
                    style={{margin:2,minWidth:0}}
                    onClick={()=>this.add(14)}
                >鍵交換
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    size="small" 
    
                    type="button"
                    style={{margin:2,float:"right"}}
                    onClick={()=>this.add(0)}
                >追加
                </Button>
             
                        <ReactDataGrid
                     
                            columns={String(this.props.estimateData.type)==="1"?this.state.columnsCorp:this.state.columns}
                            rowGetter={i => this.props.estimateMonies2[i]}
                            rowsCount={this.getRowCount()}
                            minHeight={this.props.estimateMonies2.length*36+36}
                            style={{ width: 200 }}
                            onGridRowsUpdated={this.onRowsKeiyakuUpdated}
                            enableCellSelect={true}
                            getCellActions={this.getCellActions}
                  
                        />
                    </div>





<Modal isOpen={this.state.alertopen} toggle={this.handleClose}  zIndex={3000} size="sm"  fade={false}>
                   
{/*              
                <ModalHeader toggle={()=>this.props.change_response_add_disp(0)}>新規登録</ModalHeader> */}
                <ModalBody  >
      項目が重複しています
            
             
 </ModalBody>
 <ModalFooter>
               {/* <button className="btn btn btn-danger" style={{margin:"5px"}} onClick={this.delete_auto_send}>除外する</button> */}
               <button className="btn btn btn-secondary" style={{margin:"5px"}} onClick={this.handleClose}>閉じる</button>
               </ModalFooter>
 </Modal>


            </div>

        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        estimateData: state.estimates.estimateData,
        estimateMonies1: state.estimates.estimateMonies1,
        estimateMonies2: state.estimates.estimateMonies2,
        onetimeKoumoku: state.estimates.onetimeKoumoku,
        tm_chinryou_koumokus: state.masterReducer.tm_chinryou_koumokus,
        monthTypes: state.estimates.monthTypes,
        payTypes: state.estimates.payTypes,
        estimate_onetime_flg: state.estimates.estimate_onetime_flg,
        etcTypes: state.estimates.etcTypes,
        kyouekiTypes: state.estimates.kyouekiTypes,
        suidouTypes: state.estimates.suidouTypes,
        parkingTypes: state.estimates.parkingTypes,
        hokenTypes: state.estimates.hokenTypes,
        payUpdateTypes: state.estimates.payUpdateTypes,
        // money_onetime: state.tenantsReducer.money_onetime,
        // money_update: state.tenantsReducer.money_update,
        // money_leave: state.tenantsReducer.money_leave,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_estimateData(state) {
            dispatch(Estimates.change_estimateData(state))
        },
        add_estimateMonies2(state) {
            dispatch(Estimates.add_estimateMonies2(state))
        },
        change_estimateMonies2(state) {
            dispatch(Estimates.change_estimateMonies2(state))
        },
        check_estimates(state) {
          dispatch(Estimates.check_estimates(state))
      },
      change_estimate_onetime_flg(state) {
        dispatch(Estimates.change_estimate_onetime_flg(state))
    },
    };
}

// MoneyEditComponent = reduxForm({
//     form: 'roomform', // a unique identifier for this form
//     // validate//バリデーション
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(MoneyEditComponent)

export default connect(mapStateToProps, mapDispatchToProps)(MoneyEditComponent);