import React, { Component } from 'react';

import { connect } from 'react-redux';

// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';
import InputLabel from '@mui/material/InputLabel';
import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';
import { Row, Col } from 'reactstrap';
import Button from '@mui/material/Button';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl'
import LoadingButton from '@mui/lab/LoadingButton';

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import ContractSendEdit from './ContractSendEdit';
import moment from 'moment';

class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal_ver: false,
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      scale: 10,
      confirm: false,
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />

      columnWidths: {
        workflow_count: 50,
        change_contents: 200,
        updated_user: 100,
        updated_at: 200,
        log_confirm_user: 100,
        log_confirm_at: 200,
      },
    };
    this.change_new = this.change_new.bind(this);
    this.change_old = this.change_old.bind(this);
    this.sendCheck = this.sendCheck.bind(this);
    this.sendCheck_Room = this.sendCheck_Room.bind(this);
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
  }
  change_new(e) {

    this.props.change_rent_contract_logs_new(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }
  change_old(e) {
    this.props.change_rent_contract_logs_old(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }

  sendCheck() {
    var check_contract_sheet_text = {}
    if(this.props.rent_contract_details.parking_application_id != 0){
        check_contract_sheet_text = this.props.check_contract_sheet_text
        check_contract_sheet_text.name = this.props.rent_contract_details.name1
        check_contract_sheet_text.title = '駐車場賃貸借契約書'
        check_contract_sheet_text.Mail = this.props.rent_contract_details.mail1
        check_contract_sheet_text.company = ''
        check_contract_sheet_text.message = 'メール受信後２日以内に契約書に入力してください。'
        this.props.change_check_contract_sheet_text(check_contract_sheet_text);
        // this.props.contracts_send_parking();
        this.props.contracts_sheet_check();
        this.setState({ confirm: false })
    }else{ //駐車場サイト以外
        check_contract_sheet_text = this.props.check_contract_sheet_text
        check_contract_sheet_text.name = this.props.rent_contract_details.name1
        check_contract_sheet_text.title = '駐車場賃貸借契約書'
        check_contract_sheet_text.Mail = this.props.rent_contract_details.mail1
        check_contract_sheet_text.company = ''
        check_contract_sheet_text.message = '契約内容をご確認の上、署名・捺印よろしくお願いいたします。'
        this.props.change_check_contract_sheet_text(check_contract_sheet_text);
        this.props.contracts_sheet_check();
    }
  }
  sendCheck_Room() {
    if (("," + this.props.rent_contract_details.room_setsubi + ",").indexOf(',55,') === -1) {
        if(this.props.rent_contract_details.cosigner !== 1 && this.props.rent_contract_details.cosigner !== 2){
            var check_contract_sheet_text = this.props.check_contract_sheet_text
            check_contract_sheet_text.name = this.props.rent_contract_details.name1
            check_contract_sheet_text.title = '賃貸借契約書'
            check_contract_sheet_text.Mail = this.props.rent_contract_details.mail1
            check_contract_sheet_text.company = ''
            check_contract_sheet_text.message = '重要事項説明後、契約内容をご確認の上、署名・捺印よろしくお願いいたします。'
            this.props.change_check_contract_sheet_text(check_contract_sheet_text);
            this.props.contracts_sheet_check();
        }else{
            this.props.change_send_complete_text({header:'未対応',body:'連帯保証人ありの契約は現在未対応です。'});
            this.props.change_send_complete(true)
        }
    }else{
            this.props.change_send_complete_text({header:'未対応',body:'現在電子契約での定期借家の物件に未対応です。'});
            this.props.change_send_complete(true)
    }
    this.setState({ confirm: false })
  }
  sendCheck_Corp_Room() {
    //リロケーションジャパン用
    if (("," + this.props.rent_contract_details.room_setsubi + ",").indexOf(',55,') === -1) {
        if(this.props.rent_contract_details.cosigner !== 1 && this.props.rent_contract_details.cosigner !== 2){
            var check_contract_sheet_text = this.props.check_contract_sheet_text
            check_contract_sheet_text.name = this.props.rent_contract_details.name1
            check_contract_sheet_text.title = '賃貸借契約書'
            check_contract_sheet_text.Mail = 
            check_contract_sheet_text.company = ''
            check_contract_sheet_text.message = ''
            this.props.change_check_contract_sheet_text(check_contract_sheet_text);
            this.props.contracts_sheet_corp();
        }else{
            this.props.change_send_complete_text({header:'未対応',body:'連帯保証人ありの契約は現在未対応です。'});
            this.props.change_send_complete(true)
        }
    }else{
            this.props.change_send_complete_text({header:'未対応',body:'現在電子契約での定期借家の物件に未対応です。'});
            this.props.change_send_complete(true)
    }
    this.setState({ confirm: false })
  }
//駐車場対応準備中↓
//   sendCheck_Parking() {
//     if (("," + this.props.rent_contract_details.room_setsubi + ",").indexOf(',55,') === -1) {
//         if(this.props.rent_contract_details.cosigner !== 1 && this.props.rent_contract_details.cosigner !== 2){
//             var check_contract_sheet_text = this.props.check_contract_sheet_text
//             check_contract_sheet_text.name = this.props.rent_contract_details.name1
//             check_contract_sheet_text.title = '駐車場賃貸借契約書'
//             check_contract_sheet_text.Mail = this.props.rent_contract_details.mail1
//             check_contract_sheet_text.company = ''
//             check_contract_sheet_text.message = '契約内容をご確認の上、署名・捺印よろしくお願いいたします。'
//             this.props.change_check_contract_sheet_text(check_contract_sheet_text);
//             this.props.contracts_sheet_check();
//         }else{
//             this.props.change_send_complete_text({header:'未対応',body:'連帯保証人ありの契約は現在未対応です。'});
//             this.props.change_send_complete(true)
//         }
//     }else{
//             this.props.change_send_complete_text({header:'未対応',body:'現在電子契約での定期借家の物件に未対応です。'});
//             this.props.change_send_complete(true)
//     }
//     this.setState({ confirm: false })
//   }
//駐車場対応準備中↑
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }

  toggle_ver = () => {

    this.setState(prevState => ({
      modal_ver: !prevState.modal_ver,

    }));

  }

  _onColumnResizeEndCallback(newColumnWidth, columnKey) {
    this.setState(({ columnWidths }) => ({
      columnWidths: {
        ...columnWidths,
        [columnKey]: newColumnWidth,
      },
    }));
  }


  render() {
    let ver = []
    for (var i = 1; i <= this.props.rent_contract_details.ver; i++) {
      ver.push(i)
    }
    return (

      <div>
        {this.props.rent_contract_details.counting === 1 ?
        this.props.electronic_contract == 0 ? 
            (this.props.rent_contract_workflows.status == 1 || this.props.rent_contract_workflows.status == 10) || (this.props.rent_contract_details.status == 1 || this.props.rent_contract_details.status == 10)? 
                !this.props.cloudsign_document_id ? 
                        Number(this.props.rent_contract_details.room_id) === -1 || this.props.rent_contract_details.room_id === null ?
                            <Alert severity="error">部屋を選択して審査を確定してください。</Alert> :  
                            (
                                this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814 ? 
                                <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.sendCheck_Corp_Room()}>
                                    電子契約の内容を確認する
                                </Button> :
                                <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.sendCheck_Room()}>
                                    電子契約の内容を確認する
                                </Button>
                            ) :
                <div>
                    <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="secondary" onClick={() => this.props.electronic_contract_cancel()}>
                    契約送信を取り消す
                    </Button>
                    <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.check_electronic_contract()}>
                    送信済み契約書類の確認
                    </Button>
                    <Button style={{ float: "right", margin: 5, backgroundColor:"#1ac3b6",color:"white" }} size="small" variant="contained"  onClick={() => this.props.remind_electronic_contract()}>
                    リマインド
                    </Button>
                </div> :
                    "" :
            < Alert severity="error">オーナー不承諾のため、電子契約を送信できません。</Alert> :
        ""
        }
        {this.props.rent_contract_details.counting === -1 || this.props.rent_contract_details.counting === 2 || this.props.rent_contract_details.counting === 6 ?
            this.props.electronic_contract == 0 ?
                (this.props.rent_contract_workflows.status == 1 || this.props.rent_contract_workflows.status == 10) || this.props.rent_contract_details.parking_application_id  ?
                    (!this.props.cloudsign_document_id ? 
                        (Number(this.props.rent_contract_details.room_id) === -1 || this.props.rent_contract_details.room_id === null  ?
                            <Alert severity="error">{this.props.rent_contract_details.parking_application_id != 0 ? 'GlobalParkingから新規申込がありました。' : ''}駐車場区画を選択して審査を確定してください。</Alert> :
                            <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.sendCheck()}>
                                電子契約の内容を確認する
                            </Button>
                        )
                        :
                        <div>
                            <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="secondary" onClick={() => this.props.electronic_contract_cancel()}>
                            契約送信を取り消す
                            </Button>
                            <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.check_electronic_contract()}>
                            送信済み契約書類の確認
                            </Button>
                            <Button style={{ float: "right", margin: 5, backgroundColor:"#1ac3b6",color:"white" }} size="small" variant="contained"  onClick={() => this.props.remind_electronic_contract()}>
                            リマインド
                            </Button>
                        </div>
                    )
                : (this.props.rent_contract_details.section_id == 10 ? 
                    <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.sendCheck()}>
                    電子契約テスト
                    </Button> 
                : "")
                :< Alert severity="error">オーナー不承諾のため、電子契約を送信できません。</Alert>
                :""
            
        }
        {/* 駐車場対応準備中↓ */}
        {/* {this.props.rent_contract_details.counting === 1 ?
        this.props.electronic_contract == 0 ? 
            (this.props.rent_contract_workflows.status == 1 || this.props.rent_contract_workflows.status == 10) || (this.props.rent_contract_details.status == 1 || this.props.rent_contract_details.status == 10)? 
                !this.props.cloudsign_document_id ? 
                        Number(this.props.rent_contract_details.room_id) === -1 || this.props.rent_contract_details.room_id === null ?
                            <Alert severity="error">部屋を選択して審査を確定してください。</Alert> :  
                        <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.sendCheck_Room()}>
                            電子契約の内容を確認する
                        </Button> :
                <div>
                    <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="secondary" onClick={() => this.props.electronic_contract_cancel()}>
                    契約送信を取り消す
                    </Button>
                    <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.check_electronic_contract()}>
                    送信済み契約書類の確認
                    </Button>
                    <Button style={{ float: "right", margin: 5, backgroundColor:"#1ac3b6",color:"white" }} size="small" variant="contained"  onClick={() => this.props.remind_electronic_contract()}>
                    リマインド
                    </Button>
                </div> :
                    "" :
            < Alert severity="error">オーナー不承諾のため、電子契約を送信できません。</Alert> :
        ""
        }
        {this.props.rent_contract_details.parking_application_id == 0 ? 
        this.props.rent_contract_details.counting === -1 || this.props.rent_contract_details.counting === 2 || this.props.rent_contract_details.counting === 6 ?
        this.props.electronic_contract == 0 ? 
                // ((this.props.rent_contract_workflows.status == 1 || this.props.rent_contract_workflows.status == 10) ?
                (!this.props.cloudsign_document_id ? 
                    (Number(this.props.rent_contract_details.room_id) === -1 || this.props.rent_contract_details.room_id === null ?
                        <Alert severity="error">駐車場区画を選択して審査を確定してください。</Alert> :
                        <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.sendCheck_Parking()}>
                        電子契約の内容を確認する
                    </Button>)
                            :
                            <div>
                    <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="secondary" onClick={() => this.props.electronic_contract_cancel()}>
                    契約送信を取り消す
                    </Button>
                    <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.check_electronic_contract()}>
                    送信済み契約書類の確認
                    </Button>
                    <Button style={{ float: "right", margin: 5, backgroundColor:"#1ac3b6",color:"white" }} size="small" variant="contained"  onClick={() => this.props.remind_electronic_contract()}>
                    リマインド
                    </Button>
                </div>) 
                // :"")
                :< Alert severity="error">オーナー不承諾のため、電子契約を送信できません。</Alert>:""
            :
        this.props.rent_contract_details.counting === -1 || this.props.rent_contract_details.counting === 2 || this.props.rent_contract_details.counting === 6 ?
                (this.props.rent_contract_workflows.status == 1 || this.props.rent_contract_workflows.status == 10) || this.props.rent_contract_details.counting === -1 ?
                !this.props.cloudsign_document_id ? 
                    Number(this.props.rent_contract_details.room_id) === -1 || this.props.rent_contract_details.room_id === null ?
                        <Alert severity="error">駐車場区画を選択して審査を確定してください。</Alert> :
                        (this.props.electronic_contract == 0 ?
                        <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.sendCheck()}>
                        電子契約の内容を確認する
                    </Button> :
                < Alert severity="error">オーナー不承諾のため、電子契約を送信できません。</Alert>)
                            :<div>
                            <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="secondary" onClick={() => this.props.electronic_contract_cancel()}>
                            契約送信を取り消す
                            </Button>
                            <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.check_electronic_contract()}>
                            送信済み契約書類の確認
                            </Button>
                            <Button style={{ float: "right", margin: 5, backgroundColor:"#1ac3b6",color:"white" }} size="small" variant="contained"  onClick={() => this.props.remind_electronic_contract()}>
                            リマインド
                            </Button>
                        </div>:
                            
            this.props.electronic_contract == 0 ?
                        <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.sendCheck()}>
                        電子契約の内容を確認する
                    </Button> :
                < Alert severity="error">オーナー不承諾のため、電子契約を送信できません。</Alert> : 
                ""
            // < Alert severity="error">オーナー不承諾のため、電子契約を送信できません。</Alert>
        } */}
        {/* 駐車場対応準備中↑ */}
        {ver.length > 0 ? <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">確認済</InputLabel>
          <Select
            name="old"
            id="old"
            value={this.props.rent_contract_logs_old}
            margin="dense"
            onChange={this.change_old}
            style={{ fontSize: 13, width: 150 }}
          >
            {ver.map(function (ver_value) {
              return <MenuItem value={ver_value}>{ver_value}</MenuItem>
            })

            }

          </Select> </FormControl> : ""}

        {ver.length > 0 ? <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">VER</InputLabel>
          <Select
            name="new"
            id="new"
            value={this.props.rent_contract_logs_new}
            margin="dense"
            onChange={this.change_new}
            // onChange={this.onChangeText}
            style={{ fontSize: 13, width: 150 }}
          >
            {ver.map(function (ver_value) {
              return <MenuItem value={ver_value}>{ver_value}</MenuItem>
            })

            }

          </Select>


        </FormControl> : ""}

        {ver.length > 0 && (this.props.user_details.amSectionId == 9 || this.props.user_details.amSectionId == 10) ?
          <span>
            {/* <Button style={{ marginLeft: 5 }} size="small" variant="contained" color="primary" onClick={()=>this.props.update_rent_contract_workflows(this.props.rent_contract_details_new.workflow_id)}>
            確認済を保存
          </Button> */}

            <Button style={{ marginLeft: 5 }} size="small" variant="contained" color="primary" onClick={this.toggle_ver}>
              {"ver." + this.props.rent_contract_logs_new}
            </Button></span>
          : ""}

        <Modal isOpen={this.state.modal_ver} toggle={this.toggle_ver} size="lg" fade={false}>

          <ModalHeader toggle={this.toggle_ver}>修正リスト</ModalHeader>
          <ModalBody>
            <Table
              rowHeight={34}
              rowsCount={this.props.rent_contract_logs.length}
              height={200}
              headerHeight={30}
              width={750}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              isColumnResizing={false}

            >
              <Column width={this.state.columnWidths.workflow_count} isResizable={true} columnKey="workflow_count" header={<Cell>更新回</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].workflow_count}</Cell>)} />
              <Column width={this.state.columnWidths.change_contents} isResizable={true} columnKey="change_contents" header={<Cell>更新内容</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].change_contents}</Cell>)} />
              <Column width={this.state.columnWidths.updated_user} isResizable={true} columnKey="updated_user" header={<Cell>更新者</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.users_select && this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].updated_user ? this.props.users_select.filter((a) => a.value === this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].updated_user).length > 0 ? this.props.users_select.filter((a) => a.value === this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].updated_user)[0].label : "" : ""}</Cell>)} />
              <Column width={this.state.columnWidths.updated_at} isResizable={true} columnKey="updated_at" header={<Cell>更新日時</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].updated_at ? moment(this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].updated_at).format('YYYY/MM/DD HH:mm:ss') : ""}</Cell>)} />
              <Column width={this.state.columnWidths.log_confirm_user} isResizable={true} columnKey="log_confirm_user" header={<Cell>確認者</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.users_select && this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].log_confirm_user ? this.props.users_select.filter((a) => a.value === this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].log_confirm_user).length > 0 ? this.props.users_select.filter((a) => a.value === this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].log_confirm_user)[0].label : "" : ""}</Cell>)} />
              <Column width={this.state.columnWidths.log_confirm_at} isResizable={true} columnKey="log_confirm_at" header={<Cell>確認日時</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].log_confirm_at ? moment(this.props.rent_contract_logs.sort((a, b) => b["workflow_count"] - a["workflow_count"])[rowIndex].log_confirm_at).format('YYYY/MM/DD HH:mm:ss') : ""}</Cell>)} />
            </Table>
          </ModalBody>
          <ModalFooter>

            {/* <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={this.delete_auto_send}>除外する</button>
          <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggleautodelete}>キャンセル</button> */}
            <Button style={{ marginLeft: 5, }} size="small" variant="contained" color="primary" onClick={() => this.props.update_rent_contract_workflows(this.props.rent_contract_details_new.workflow_id)}>
              確認済を保存
            </Button>
          </ModalFooter>
        </Modal>

      </div>

    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    check_contract_sheet_text: state.contracts.check_contract_sheet_text,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_logs_new: state.contracts.rent_contract_logs_new,
    rent_contract_logs_old: state.contracts.rent_contract_logs_old,
    user_details: state.masterReducer.user_details,
    rent_contract_logs: state.contracts.rent_contract_logs,
    users_select: state.masterReducer.users_select,
    check_contract_button: state.contracts.check_contract_button,
    electronic_contract: state.contracts.electronic_contract,
    cloudsign_document_id: state.contracts.cloudsign_document_id,
    rent_contract_workflows: state.contracts.rent_contract_workflows,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_check_contract_sheet_text(state) {
      dispatch(Contracts.change_check_contract_sheet_text(state))
    },
    change_rent_contract_logs_new(state) {
      dispatch(Contracts.change_rent_contract_logs_new(state))
    },
    change_rent_contract_logs_old(state) {
      dispatch(Contracts.change_rent_contract_logs_old(state))
    },
    change_rent_contract_logs_ver(state) {
      dispatch(Contracts.change_rent_contract_logs_ver(state))
    },
    contracts_send_parking(state) {
      dispatch(Contracts.contracts_send_parking(state))
    },
    update_rent_contract_workflows(state) {
      dispatch(Contracts.update_rent_contract_workflows(state))
    },
    change_check_contract_sheet(state) {
      dispatch(Contracts.change_check_contract_sheet(state))
    },
    contracts_sheet_check(state) {
      dispatch(Contracts.contracts_sheet_check(state))
    },
    contracts_sheet_corp(state) {
      dispatch(Contracts.contracts_sheet_corp(state))
    },
    electronic_contract_cancel(state) {
      dispatch(Contracts.electronic_contract_cancel(state))
    },
    check_electronic_contract(state) {
      dispatch(Contracts.check_electronic_contract(state))
    },
    remind_electronic_contract(state) {
      dispatch(Contracts.remind_electronic_contract(state))
    },
    change_send_complete_text(state) {
        dispatch(Contracts.change_send_complete_text(state))
    },
    change_send_complete(state) {
        dispatch(Contracts.change_send_complete(state))
    },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
